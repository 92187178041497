<template>
  <q-dialog
    v-model="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
    :maximized="$q.platform.is.mobile ? maximizedToggle : false"
    class="q-pa-md justify-center"
    position="bottom"
    persistent
  >
    <q-card
      class="q-ma-md q-pa-md"
      :style="{ 'font-family': settings.typography }"
    >
      <q-card-section class="justify-between row q-pt-md q-pb-md">
        <div class="text-subtitle1">
          {{ $t("BuyingProcess.products.home-delivery") }}
        </div>
      </q-card-section>
      <q-card-section
        class="text-weight-bold cursor-pointer q-ml-md q-ma-none q-mb-md q-pa-none"
        style="text-decoration: underline"
        @click="sendHomeAddress()"
        v-close-popup
      >
        {{ $t("BuyingProcess.products.send-to-my-profile") }}
      </q-card-section>

      <q-card-section
        class="text-weight-bold q-ml-md q-ma-none q-pa-none q-mb-xs"
      >
        {{ $t("BuyingProcess.products.new-address") }}
      </q-card-section>

      <q-list class="q-pl-md q-pt-none q-pr-md q-mt-none">
        <q-item class="q-ma-none q-pa-none">
          <q-item-section>
            <q-item-label class="q-ma-sm">
              {{ $t("Profile.personal-address.address") }}
            </q-item-label>
            <q-input
              class="q-ma-none q-ml-md q-pa-none"
              type="text-area"
              filled
              v-model="addressCopy"
              maxlength="100"
            />
          </q-item-section>
        </q-item>
        <!-- <q-item class="q-ma-none q-pa-none">
          <q-item-section>
            <q-item-label class="q-ma-sm">
              {{ $t("Profile.personal-address.postalcode") }}
            </q-item-label>
            <q-input
              class="q-ma-none q-ml-md q-pa-none"
              filled
              v-model="addressCopy.PostalCode"
            />
          </q-item-section>
        </q-item>
        <q-item class="q-ma-none q-pa-none">
          <q-item-section>
            <q-item-label class="q-ma-sm">
              {{ $t("Profile.personal-address.city") }}
            </q-item-label>
            <q-input
              class="q-ma-none q-ml-md q-pa-none"
              filled
              v-model="addressCopy.City"
            />
          </q-item-section>
        </q-item>
        <q-item class="q-ma-none q-pa-none">
          <q-item-section>
            <q-item-label class="q-ma-sm">
              {{ $t("Profile.personal-address.country") }}
            </q-item-label>
            <q-input
              class="q-ma-none q-ml-md q-pa-none"
              filled
              v-model="addressCopy.Country"
            />
          </q-item-section>
        </q-item> -->
        <!-- <q-item class="q-ma-none q-pa-none">
          <q-item-section>
            <q-item-label class="q-ma-sm">
              {{ $t("Profile.personal-address.comments") }}
            </q-item-label>
            <q-input
              class="q-ma-none q-ml-md q-pa-none"
              filled
              type="textarea"
              v-model="addressCopy"
              maxlength="30"
            />
          </q-item-section>
        </q-item> -->
      </q-list>
      <div class="flex justify-center q-mt-lg">
        <q-btn
          class="cursor-pointer text-center"
          :label="$t('General.save')"
          v-close-popup
          @click="changeAddressState()"
          :style="{ backgroundColor: settings.color_accent }"
        />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from "vue";
import { mapState } from "vuex";
import { defineAsyncComponent } from "vue";
import settings from "@/mixins/settings";

const GoBackButton = defineAsyncComponent(() =>
  import("../../shared/buttons/GoBackButton.vue")
);

const ButtonOptions = defineAsyncComponent(() =>
  import("../../shared/buttons/ButtonOptions.vue")
);

export default {
  mixins: [settings],

  components: {
    GoBackButton,
    ButtonOptions,
  },
  data() {
    return {
      addressCopy: "",
    };
  },
  props: {
    dialog: {
      default: false,
    },
    product: {
      type: Object,
    },
    points: {
      type: Object
    },
  },
  setup() {
    return {
      maximizedToggle: true,
      color: ref("cyan"),
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    // Envía un emit con la Dirección del Perfil
    sendHomeAddress() {
      const infoProfile = this.user.shipping_address;
      this.$emit("sendHomeAddress", infoProfile);
    },

    changeAddressState() {
      this.$emit("sendAddress", this.addressCopy);
    },
  },
  mounted() {
    this.addressCopy = this.user.shipping_address;
  },
};
</script>

<style lang="scss" scoped>
@import "~quasar-variables";

.q-card {
  background-color: white;
  height: 450px;
}

.q-radio {
  display: flex;
  justify-content: space-between;
  font-size: 50px;
}

.q-icon {
  color: v-bind("color_primary");
}

@media (min-width: 600px) {
  .q-dialog__inner--minimized > div {
    max-width: 560px;
    position: relative;
    left: 26%;
    min-width: 466px;
    top: 0px;
    min-height: 300px;
    border-radius: 25px 25px 25px 0px !important;
    box-shadow: 0px 8px 16px 0px #00000033;
  }
}

.q-dialog__inner--maximized > div {
  border-radius: 25px 25px 25px 0px !important;
  top: 200px;
  box-shadow: 0px 8px 16px 0px #00000033;
}

.q-btn {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  margin-top: 30px;
  text-transform: capitalize;
  width: 200px;
  color: white;
  background-color: v-bind("settings.color_primary");
}
</style>
